import React from 'react'
import * as Styled from './styled'
import { MdSupportAgent } from 'react-icons/md'
import BlueIconComp from 'common/components/Icons/BlueIconComp'
import { useAppSelector } from 'redux/store/store'
import { ChatwootWidget } from 'features/ChatwootWidget'

const styleIcon = { fontSize: '35px' }

const SupportPage: React.FC = () => {
  const { userInfo } = useAppSelector(state => state.AuthReducer)

  React.useEffect(() => {
    const refWindow: any = window
    if (refWindow?.$chatwoot) {
      refWindow.$chatwoot.toggle('open') // To open widget
      // refWindow.$chatwoot.toggleBubbleVisibility('show')

      refWindow.$chatwoot.setUser(userInfo?._id, {
        email: `${userInfo?.phone}@gmail.com`,
        name: userInfo?.name,
        avatar_url: userInfo.avatar,
        phone_number: userInfo?.phone,
      })
    } else {
      window.addEventListener('chatwoot:ready', function () {
        refWindow.$chatwoot?.toggle('open') // To open widget
      })
    }
    return () => {
      refWindow.$chatwoot?.toggle('close') // To close widget
      // refWindow.$chatwoot.toggleBubbleVisibility('hide')
    }
  }, [])
  return (
    <Styled.Container>
      <Styled.WarpBox>
        <Styled.Block>
          <Styled.Title>
            <BlueIconComp icon={<MdSupportAgent style={styleIcon} />} />
            Dịch vụ chăm sóc khách hàng
          </Styled.Title>
          <Styled.Note>
            Nếu bạn có vấn đề gì cần hỗ trợ vui lòng liên hệ chăm sóc khách hàng
            trực tuyến
          </Styled.Note>
          <Styled.Title>
            <BlueIconComp icon={<MdSupportAgent style={styleIcon} />} />
            Lịch sử của Dior
          </Styled.Title>
          <Styled.Note>
           Thành lập: Dior được thành lập vào năm 1946 bởi Christian Dior, một nhà thiết kế thời trang người Pháp. Ban đầu, thương hiệu hoạt động dưới tên gọi "Maison Christian Dior".
Dấu ấn lịch sử: Dior đã trở thành biểu tượng của sự đổi mới trong ngành thời trang với bộ sưu tập "New Look" ra mắt vào năm 1947. Bộ sưu tập này đã làm thay đổi hoàn toàn hình ảnh thời trang nữ giới, với những chiếc váy có eo thắt chặt, chân váy rộng và các chi tiết tinh tế, mang lại vẻ nữ tính và quý phái cho phụ nữ sau chiến tranh.
Sự phát triển sau cái chết của Christian Dior: Sau khi Christian Dior qua đời vào năm 1957, các nhà thiết kế như Yves Saint Laurent, Marc Bohan, John Galliano, Raf Simons, và gần đây là Maria Grazia Chiuri (hiện tại là giám đốc sáng tạo của Dior) đã tiếp tục phát triển và làm mới thương hiệu.
          </Styled.Note>
          <Styled.Title>
            <BlueIconComp icon={<MdSupportAgent style={styleIcon} />} />
            Quy tắc lấy đơn hàng
          </Styled.Title>
          <Styled.Note>
            Để ngăn chặn các hoạt động bất hợp pháp rửa tiền Theo Khoản 1, Điều
            3 Nghị định số 74/2005/NĐ-CP ngày 7/6/2005, người dùng phải hoàn
            thành nhiều nhiệm vụ và rút tiền mặt trong cùng một ngày. Sau khi
            xác nhận rút tiền thành công, thời gian nhận là 1 ~ 5 phút,Khoảng
            thời gian cao điểm không quá 30 phút, và thời gian nhận do các ngân
            hàng. Tham gia công việc bằng phương thức nhận đơn hàng làm nhiệm
            vụ:
            <div>① Đăng ký tài khoản</div>
            <div>② Nạp tiền online</div>
            <div>③ Nhận đơn hàng</div>
            <div>④ Hoàn thành đơn hàng</div>
            <div>⑤ Rút tiền gốc</div>
          </Styled.Note>
        </Styled.Block>
      </Styled.WarpBox>
    </Styled.Container>
  )
}
export default SupportPage
